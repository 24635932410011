@font-face {
  font-family: "Rubik Regular";
  src: url("../src/fonts/Rubik-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Rubik Light";
  src: url("../src/fonts/Rubik-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Rubik Medium";
  src: url("../src/fonts/Rubik-Medium.ttf") format("truetype");
}

:root {
  --bd-violet-bg: #0d25ff;
  --bg-dark:#0E0E0E
}
.rtl{
  direction: rtl;
}

a, a.link{
  color:#fff;
}
a:has(svg), .link{
  display: contents;
}

body ,html {
  margin: 0;
  padding :0;
  width:100%;
  background-color: var(--bg-dark);
}

.sticky-top{
  background-color: #fff;
}
.gallery-wrap{
  background: #fff;
  padding-bottom: 6rem;
}
.gallery .actions{
  width:70px;
  gap: 20px;
  height: max-content;
}
.gallery .actions .social-icon{
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: #000;
  display: flex;
  align-items: center;
  margin: auto;
  cursor: pointer;
}

.gallery .actions .social-icon:hover{
  background: var(--bd-violet-bg)
}

.gallery .actions .social-icon svg{
  vertical-align: middle;
  margin: auto;
  color: #fff;
  height: 22px;
}

.carousel-item{
  /* background: linear-gradient(rgba(0,0,0,1),rgba(0,0,0,0)), url('img/img_1.jpeg'); */
  background-repeat: no-repeat;
  background-size: cover;
  height:120vw;
}

.carousel-item::after{
  position: absolute;
  content: '';
  width: 100%;
  height: 20vw;
  background: linear-gradient(rgba(0,0,0,1),rgba(0,0,0,0));
}

.gallery-text-container{
  font-family: 'Rubik Regular';
  position: absolute;
  z-index: 5;
  bottom: 0;
  top:0;
}

.gallery-text-container button{
  width: fit-content;
}

.btn-primary {
  --bs-btn-font-weight: 600;
  --bs-btn-color: var(--bs-white);
  --bs-btn-bg: var(--bd-violet-bg);
  --bs-btn-border-color: var(--bd-violet-bg);
  padding: 6px 50px;
  font-family: 'Rubik Regular';
  width:fit-content;
}

.gallery-text{
  color: #FFF;
  font-style: normal;
}

.gallery-text .sub-title{
  font-size: 75px;
  font-weight: 400;
  line-height: 90px;
}

.gallery-text .title{
  font-size: 75px;
  font-weight: 900;
  line-height: 90px;
}

.gallery-text .caption{
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  font-size: 30px;
  font-weight: 600;
  line-height: normal;
}

.carousel-indicators {
  bottom: -55px;
}

.carousel-indicators [data-bs-target] {
  width: 14px;
  border-radius: 100%;
  height: 14px;
  margin-right: 8px;
  margin-left: 8px;
  background-color: #868686;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 1;
  transition: opacity .6s ease;
}

.carousel-indicators .active {
  background-color: #0d25ff;
}

.content-section-wrap{
  /*margin-top: 6rem;*/
}

.content-section{
  background-color: var(--bg-dark);
  padding: 70px;
  padding-bottom: 20px;
  color:#FFF;
  font-size: 22px;
  font-family: 'Rubik Light';
}

.content-section .title{
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.18px;
  line-height: normal;
  padding-bottom: 30px;
}

.content-section .body{
  font-size: 20px;
  padding: 50px 35px 50px 15px;
}

.content-image{
  border-radius: 20px;
  height: 22vw;
  background-image: url(img/content.png);
  background-size: 120%;
  background-clip: content-box;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
}

.story-section-wrap{
  padding-left: 70px;
  margin-top: -130px;
  z-index: 100;
  position: relative;
}

.story-section{
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.75);
  box-shadow: 0px 4px 27px 8px rgba(0, 0, 0, 0.10);
  padding: 30px;
  width: 90%;
  color: #FFF;
  font-family: 'Rubik Regular';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 180%; /* 39.6px */
  letter-spacing: 0.11px;
}

.footer-section img.footer_img{
  width:100%;
}

.footer-links-section-wrap{
  background: #0E0E0E;
  color: #FFF;
  width:100%
}

.footer-links-section{
  padding: 30px 70px 30px 70px; 
}

.footer-links-section .title{
  color: #EEE;
font-size: 20px;
font-style: normal;
font-weight: 900;
line-height: 30px; /* 61.538% */
}

.credit{
  padding-top: 20px;
}

.website-section .credit{
  display: none;
}

.contact-section .credit{
  display: block;
}

.credit img{
  margin: 0 20px;
}

@media (max-width: 768px) {
  .container{
    --bs-gutter-y: 0;
    --bs-gutter-x: 0;
  }

  .website-section .credit{
    display: block;
  }

  .contact-section .credit{
    display: none;
  }

  .carousel-item:after{
    height: 90vw;
  }
  .carousel-item {
    height: 420px;
}
  .gallery-text .sub-title{
      font-size: 35px;
      line-height: normal;
  }

  .gallery-text{
      text-align: center;
  }

  .gallery-text .title{
      font-size: 40px;
      line-height: 60px;
  }
  
  .gallery-text .caption{
      margin-top: 30px;
  }

  .gallery .actions{
    width:auto;
    gap: 10px;
    margin-top: 60px;
  }

  .gallery-text-container button{
    display: none;
  }

  .content-section{
    padding: 50px 30px 50px 30px;
    text-align: center;
  }

  .story-section-wrap{
    padding: 0 30px;
    margin-top: 0;
    z-index: 100;
    position: relative;
  }

  .story-section{
    text-align: center;
    padding: 30px;
    width: 100%;
  }

  .story-section button{
    display: none;
  }

  .content-section button{
    display: none;
  }

  .footer-section{
    padding-left: 0px;
  }

  .footer-links-section{
    padding: 30px;
    padding-bottom: 120px;
  }
  


  .footer-links-section button{
    display: none;
  }

  .sticky-button{
    display: block!important;
    bottom:20px;
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
  }

  .sticky-button button{
    width:100% ;
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: 20px;
  }

  .gallery .actions{
    width:auto;
    gap: 0px;
    margin-top: 60px;
  }

  .content-section-wrap {
    margin-top: 0rem;
  }

  .sticky-button{
    display: block!important;
    bottom:2vh;
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
  }
  .carousel-item::after{
    height:35vw;
  }
  .content-image{
    height: 40vh;
  }
  .gallery-wrap{
    background: #fff;
    padding-bottom: 3rem;
  }
}

@media (min-width: 768px) { 
  .carousel-item{
    border-radius: 20px!important;
    height:38vw;
  }
  .carousel-item::after{
    border-radius: 20px!important;
  }
}

@media (min-width: 1450px) { 
  .carousel-item{
    border-radius: 20px!important;
    background-size:cover;
    background-position: center;
    height:36vw;
  }
  .carousel-item::after{
    border-radius: 20px!important;
  }
}